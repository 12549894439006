import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"improve-it"} />
		<Helmet>
			<title>
				Our Mission, Your Support
			</title>
			<meta name={"description"} content={"Your participation funds the development of resources to teach pattern recognition mastery, preparing individuals for successful personal and professional lives."} />
			<meta property={"og:title"} content={"Our Mission, Your Support"} />
			<meta property={"og:description"} content={"Your participation funds the development of resources to teach pattern recognition mastery, preparing individuals for successful personal and professional lives."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/proveit.webp?v=2024-03-04T16:16:07.691Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/logo.png?v=2024-02-01T23:01:44.467Z"} />
		</Helmet>
		<Components.NavBar>
			<Override slot="quarklycommunityKitMenuOverride3" />
			<Override slot="image" />
			<Override slot="LogoIco" />
		</Components.NavBar>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--primary"
					font="--headline1"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
					as="h1"
				>
					Something special is brewing!
				</Text>
			</Box>
			<Box
				display="grid"
				md-flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="16px 24px"
				md-grid-template-columns="1fr"
			>
				<Box
					display="grid"
					flex-direction="column"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					justify-content="space-between"
					grid-gap="16px"
					align-content="start"
				>
					<Image
						src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21:29:52.624Z"
						width="100%"
						object-fit="cover"
						top={0}
						left={0}
						bottom={0}
						right={0}
						max-height="100%"
						height="230px"
						border-radius="15px"
						srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21%3A29%3A52.624Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21%3A29%3A52.624Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21%3A29%3A52.624Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21%3A29%3A52.624Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21%3A29%3A52.624Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21%3A29%3A52.624Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_blue.jpg?v=2024-02-03T21%3A29%3A52.624Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text color="--primary" font="--lead" md-text-align="center" align-self="start">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Pattern recognition
						</Strong>
						{" "}is an indispensable skill that profoundly{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							shapes our interactions
						</Strong>
						{" "}with the world,{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							crucial for decoding
						</Strong>
						{" "}everything from written language to{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							social behaviours
						</Strong>
						. It enables us to recognize faces, navigate traffic efficiently, and{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							understand complex environments
						</Strong>
						. For example, the ability to discern patterns in letters and words is fundamental to reading, while in driving, it helps predict other drivers' actions based on vehicle movements.
					</Text>
					<Text color="--primary" font="--lead" md-text-align="center" align-self="start">
						This skill also plays a vital role in our ability to{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							make strategic, long-term decisions
						</Strong>
						. In{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							finance, recognizing patterns
						</Strong>
						{" "}in market movements can
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}influence crucial investment decisions
						</Strong>
						, and in healthcare, it{" "}
						<Strong>
							allows doctors to diagnose
						</Strong>
						{" "}diseases by interpreting patterns in symptoms and test results.
					</Text>
				</Box>
				<Box
					display="grid"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					grid-gap="16px"
					align-content="start"
				>
					<Image
						src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21:29:55.428Z"
						width="100%"
						object-fit="cover"
						top={0}
						left={0}
						bottom={0}
						right={0}
						max-height="100%"
						height="230px"
						border-radius="15px"
						md-order="1"
						srcSet="https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21%3A29%3A55.428Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21%3A29%3A55.428Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21%3A29%3A55.428Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21%3A29%3A55.428Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21%3A29%3A55.428Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21%3A29%3A55.428Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65b3caaa009abe001f026b50/images/geometric_patterns_orange.jpg?v=2024-02-03T21%3A29%3A55.428Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text color="--primary" font="--lead" md-text-align="center" align-self="start">
						Moreover, pattern recognition is{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							essential for social
						</Strong>
						{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							integration
						</Strong>
						{" "}and{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							community participation
						</Strong>
						. It aids in{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							understanding cultural cues
						</Strong>
						{" "}and
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}social norms
						</Strong>
						, which are vital for fostering strong interpersonal relationships and{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							effective collaboration
						</Strong>
						{" "}in group settings like workplaces and schools.
					</Text>
					<Text color="--primary" font="--lead" md-text-align="center" align-self="start">
						At IQ Researches,{" "}
						<Strong>
							our mission
						</Strong>
						{" "}is to{" "}
						<Strong>
							globally enhance pattern recognition skills
						</Strong>
						, recognizing their pivotal role in navigating complex social structures. By taking our Cognitive IQ Test, you assess your abilities and support our goal to disseminate this{" "}
						<Strong>
							critical skill worldwide
						</Strong>
						.{" "}
						<Strong>
							Your participation funds the development of resources to teach pattern recognition mastery, preparing individuals for successful personal and professional lives.
						</Strong>
						{" "}If you're interested in supporting our efforts in other ways or need more information, please feel free to contact us. Join us in this transformative initiative—
						<Strong>
							your support can truly make a significant impact.
						</Strong>
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<link href={"https://iqresearches.com/improve-it/"} rel={"canonical"} place={"endOfHead"} rawKey={"661e63eff5ce09936902858f"} />
		</RawHtml>
	</Theme>;
});